import { Component, OnInit, Input, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
//import { ModalModule, Modal } from 'ng2-modal';
//import 'rxjs/add/operator/map';
import { Observable, Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { QueryService } from "../../service/query.service";
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";
import { ModalDialogService } from '../modal-dialog.service';




@Component({
    moduleId: module.id.toString(),
    selector: "confirm_anonymize",
    templateUrl: './confirm-anonymize-dialog.component.html',
    styleUrls: ['./confirm-anonymize-dialog.component.scss']
})

export class ConfirmAnonymizeDialogComponent {
    anonymizeReason: any = {};

    @ViewChild('anonymizeModal', { static: true }) anonymizeModal: ModalDirective;
    observable: Observable<string>;
    subject: Subject<string>;
    returnedValue: string = 'closed';
    deleteComments: string = "";
    source: number = 0;

    //discussionTitle: string = '';
    deleteReason: string = '';

    @Input('anonymizeReason') anonymizeReasons: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };
 
    constructor(private injector: Injector, private queryService: QueryService, private logTypes: LogTypes,
        private logService: LogService, private dialogService: ModalDialogService) {
        this.anonymizeReason = this.injector.get('anonymizeReason');
       
    }


    onModalClosed($event: any) {
        if (this.subject) {
            this.subject.next(this.returnedValue);
        }

        for (let subscriber of this.subject.observers) {
            subscriber.complete();
        }
        this.componentEvent.emit({ value: "closed" });
       
    }

    openDialog() {
        //this.returnedValue = "closed";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.anonymizeModal.config = this.config;
        this.anonymizeModal.show();
        //this.customizePopup();
        return this.observable;


    }

    closeDialog() {
        this.anonymizeModal.hide();
    }

    submitClicked() {

        if (this.deleteComments != 'NULL') {
            let config1 = {
                type: 'LOCATION_DELETE',
                action: {
                    cancel: "Cancel",
                    ok: "Ok"
                }
            };

            let dialog = this.dialogService.confirm(
                'Confirm Action',
                'Are you sure to Anonymize this user?',
                config1);
            dialog.subscribe(
                (data) => {
                    if (data && data === 'yes') {

                        localStorage.setItem('anonymizeValue', this.deleteComments);  
                        this.onModalClosed(this.deleteComments);
                    }
                }
            )
           
        }
        
    }

    cancelClicked() {
        this.onModalClosed(null);
    }

    

}