export class LogAction {
    constructor(
    ) { }
    public action: string;
    public driverId: number;
    public themeId: number;
    public subThemeId: number;
    public threadId: number;
    public postId: number;
    public eventId: number;
    public cardId: number;
    public growthPlanId: number;
    public communityId: number;
    public searchText: string;
    public searchHits: number;
    public surveyQuestionId: number;
    public topicId: number;
    public eventUserId: number;
    public eventRoleId: number;
    public otherInfo: string;
    public isExternal: boolean;
    public tagId: number;
    public categoryId: number;
    public reportId: number;
    public labelId: number;
    public enquiryId: number;
    public helpId: number;
    public privacyId: number;
    public videoId: number;
    public membershipId: number;
    public solutionId: number;
}

export class LogTypes {

    LOGGED_IN: string = "Logged in";
    LOGGED_OUT: string = "Logged out";
    TIME_OUT: string = "Timed out";
    COOKIE_ACCEPTED: string = "Accepted Cookie";
    AUTHORIZATION_FAILED: string = "Failed authorization";
    SEARCHED: string = "Searched";

    ASSESMENT_COMPLETED: string = "Completed Assessment";
    GROWTH_PLAN_MODIFIED: string = "Modified growth plan";
    COMPLETED_CARD: string = "Completed Card Action";

    HOT_TOPIC_ACCESSED: string = "Accessed a hot topic";   //May be Duplicate 
    HOT_TOPIC_LIKED: string = "Liked a hot topic";
    HOT_TOPIC_UNLIKED: string = "unliked a hot topic";
    HOT_TOPIC_VIEWED: string = "Viewed a hot topic";
    HOT_TOPIC_PINNED: string = "Pinned a hot topic";
    HOT_TOPIC_CREATED: string = "Created Hot Topic";
    HOT_TOPIC_MODIFIED: string = "Modified Hot Topic";
    HOT_TOPIC_DEACTIVATED: string = "Deactivated Hot Topic";
    HOT_TOPIC_ACTIVATED: string = "Activated Hot Topic";

    SURVEY_ANSWERD: string = "Answered a survey";
    SURVEY_CREATED: string = "Created Survey";
    SURVEY_MODIFIED: string = "Modified Survey";
    SURVEY_ACTIVATED: string = "Activated Survey";
    SURVEY_DEACTIVATED: string = "Deactivated Survey";

    COMMUNITY_JOINED: string = "Joined Community";
    COMMUNITY_MODIFIED: string = "Modified a community";
    COMMUNITY_CREATED: string = "Created Community";
    COMUNTIY_ACTIVATED: string = "Activated Community";
    COMMUNITY_DEACTIVATED: string = "Deactivated Community";

    DISCUSSION_POSTED: string = "Posted a discussion";
    DISCUSSION_LIKED: string = "Liked a discussion";
    DISCUSSION_UNLIKED: string = "Unliked a discussion";
    DISCUSSION_FOLLOWED: string = "Followed a discussion";
    DISCUSSION_UNFOLLOWED: string = "Unfollowed a discussion";
    DISCUSSION_CREATED: string = "Created a discussion";    //May be Duplicate 
    DISCUSSION_MODIFIED: string = "Modified a discussion";
    DISCUSSION_ACTIVATED: string = "Activated a discussion";
    DISCUSSION_DEACTIVATED: string = "Deactivated a discussion";

    INSIGHT_PINNED: string = "Pinned a Insight";
    TOOL_PINNED: string = "Pinned a tool";
    TOOL_SUBSCRIBED: string = "Subscribed a tool";

    CARD_ACCESSED: string = "Accessed a card";
    CARD_CREATED: string = "Created card";
    CARD_MODIFIED: string = "Modified card";
    CARD_ACTIVATED: string = "Activated card";
    CARD_DEACTIVATED: string = "Deactivated card";

    EVENT_SUBSCRIBED: string = "Subscribed an Event";
    EVENT_CREATED: string = "Created Event";
    EVENT_MODIFIED: string = "Modified Event";
    EVENT_ACTIVATED: string = "Activated Event";
    EVENT_DEACTIVATED: string = "Deactivated Event";

    THREAD_COMMENTED: string = "Commented on a thread";     //May be Duplicate 
    ANSWER_POSTED: string = "Posted an answer";
    ANSWER_LIKED: string = "Liked an answer";
    ANSWER_UNLIKED: string = "Unliked an answer";
    ANSWER_MODIFIED: string = "Modified an answer";
    ANSWER_ACTIVATED: string = "Activated an answer";
    ANSWER_DEACTIVATED: string = "Deactivated an answer";

    USER_CREATED: string = "Created User";
    USER_MODIFIED: string = "Modified User";
    USER_ACTIVATED: string = "Activated User";
    USER_DEACTIVATED: string = "Deactivated User";
    USER_DELETED: string = "Deleted User";

    ROLE_CREATED: string = "Created Role";
    ROLE_MODIFIED: string = "Modified Role";
    ROLE_ACTIVATED: string = "Activated Role";
    ROLE_DEACTIVATED: string = "Deactivated Role";

    MEMBER_DELETED: string = "Deleted Member";
    MEMBER_DECLINED: string = "Declined Member";
    MEMBER_APPROVED: string = "Approved Member";
    MEMBER_ANONYMIZED: string = "Anonymized Member";

    MODIFIED_ROLE = "Modified User Role";
    MODIFIED_MEMBERSHIP = "Modified User Membership";
    MODIFIED_USER_TYPE = "Modified User ClientType";

    DRIVER_MODIFIED:string = 'Modified Driver';
    SUBDRIVER_MODIFIED: string =  'Modified Subdriver';
    THEME_MODIFIED: string =  'Modified Theme';
    SUBDRIVER_DEACTIVATED: string =  'Deactivated Subdriver';
    THEME_DEACTIVATED: string =  'Deactivated Theme';

    VELOCITY_CHAMPION_CREATED: string = "Created VelocityChampion";
    VELOCITY_CHAMPION_MODIFIED: string = "Modified VelocityChampion";
    VELOCITY_CHAMPION_ACTIVATED: string = "Activated Velocity Champion";
    VELOCITY_CHAMPION_DEACTIVATED: string = "Deactivated Velocity Champion";

    TAG_CATEGORY_CREATED: string = "Created Tag Categoty"
    TAG_CATEGORY_MODIFIED: string = "Modified Tag Categoty"
    TAG_CATEGORY_DELETED: string = "Deleted Tag Categoty"

    TAG_CREATED: string = "Created Tag"
    TAG_MODIFIED: string = "Modified Tag"
    TAG_DELETED: string = "Deleted Tag"

    DELETION_REQUEST_CREATED: string = "Created Deletion Request";
    DELETION_REQUEST_REJECTED: string = "Rejected Deletion Request";
    DELETION_REQUEST_APPROVED: string = "Approved Deletion Request";

    TIMEZONE_CHANGED: string = "Changed Timezone";
    NOTIFICATION_ENABLED: string = "Enabled Notification";
    NOTIFICATION_DISABLED: string = "Disabled Notification";

    LABEL_MODIFIED: string = "Modified Labels";
    HELP_MODIFIED: string = "Modified HelpContents";
    CONTACTUS_DELETED: string = "Deleted ContactUs";

    TC_UPLOADED: string = "Uploaded T&C";
    TC_ACTIVATED: string = "Activated T&C";
    TC_DEACTIVATED: string = "Deactivated T&C";
    CODEOFCONDUCT_UPLOADED: string = "Uploaded CodeOfConduct";
    PRIVACY_UPLOADED: string = "Uploaded PrivacyPolicy";
    SHORTPRIVACY_UPLOADED: string = "Uploaded ShortPrivacyPolicy";
    COPYRIGHT_MODIFIED: string = "Modified Copyright";
    WHYEY_MODIFIED: string = "Modified Why EY";
    ABOUTEYVELOCITY_MODIFIED: string = "Modified About Velocity";

    SHOWCASE_VIDEO_CREATED: string = "Created Showcase Video";
    SHOWCASE_VIDEO_MODIFIED: string = "Modified Showcase Video";
    SHOWCASE_VIDEO_ACTIVATED: string = "Activated Showcase Video";
    SHOWCASE_VIDEO_DEACTIVATED: string = "Deactivated Showcase Video";

    SOCIALMEDIA_MODIFIED: string = "Modified Socialmedia Links";

    ADDED_RECOMMENDED_TOOL = "Added Recommended Tool";
    REMOVED_RECOMMENDED_TOOL = "Removed Recommended Tool";

    CREATED_MEMBERSHIP_CODE = "Created Membership";
    MODIFIED_MEMBERSHIP_CODE = "Modified Membership";
    DEACTIVATED_MEMBERSHIP_CODE = "Deactivated Membership";
    COOKIE_MODIFIED: string = "Modified Cookie Settings";

    //Add three variable values
    GN_MEETING_PORT="GN Meeting port";

    CREATED_TRANSFORMATIVE_SOLUTION = "Created Transformative Solutions";
    MODIFIED_TRANSFORMATIVE_SOLUTION = "Modified Transformative Solutions";
    DEACTIVATED_TRANSFORMATIVE_SOLUTION = "Deactivated Transformative Solutions";

    constructor() {

    }
}