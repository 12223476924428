import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './auth.service';
import { StorageHelperService } from './../../service/storage-helper.service';
import { TimerService } from '../../service/timer.service';


@Injectable()
export class UserService {
    storageType: string = "session";//"local"
    isMessagePopupClosed: boolean = false;
    inProgressMsal: boolean = false;
    private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public currentUserObservable = this.currentUserSubject.asObservable();
    private authenticationService: AuthenticationService;
    constructor(
        //private authenticationService: AuthenticationService,
        private storageHelperService: StorageHelperService,
        private timer: TimerService
    ) {
    }
    setAuthenticationService(authenticationService) {
        this.authenticationService = authenticationService;
    }

    getCurrentUser() {
        var currentUser: any;
        if (this.storageType == "session") {
            currentUser = this.storageHelperService.GetSessionValue("currentuser");
        }
        else {
            currentUser = this.storageHelperService.GetLocalValue("currentuser");
        }
                
        return currentUser;
    }

    setCurrentUser(user: any) {
        if (this.storageType == "session") {
            this.storageHelperService.SetSessionValue("currentuser", user);
        }
        else {
            this.storageHelperService.SetLocalValue("currentuser", user);
        }
        this.currentUserSubject.next(user);
        this.isLoggedInSubject.next(true);
    }



    setUserProfileImage(userProfileImage: any) {
        var currentUser: any;
        //get user
        if (this.storageType == "session") {
            currentUser = this.storageHelperService.GetSessionValue("currentuser");
        }
        else {
            currentUser = this.storageHelperService.GetLocalValue("currentuser");

        }
        currentUser.profileUrl = userProfileImage;

        //set user
        if (this.storageType == "session") {
            this.storageHelperService.SetSessionValue("currentuser", currentUser);
        }
        else {
            this.storageHelperService.SetLocalValue("currentuser", currentUser);
        }
        this.currentUserSubject.next(currentUser);

        //   this.currentUserSubject.next(currentUser);
    }

    setUserPreferredName(userPreferredName: any) {

        var currentUser = this.getCurrentUser();
        currentUser.preferredName = userPreferredName;
        this.setCurrentUser(currentUser);
    }

    logout() {
        if (this.storageType == "session") {
            this.storageHelperService.ClearSessionValue("currentuser");
        }
        else {
            this.storageHelperService.ClearLocalValue("currentuser");
        }
        this.isLoggedInSubject.next(false);
        this.currentUserSubject.next(null);
        this.timer.stopAndKill();
        this.timer.clearStartTime();
    }

    logoutmanual() {
        setTimeout(() => {
            var currentUser = this.getCurrentUser();
            if (currentUser != null) {
                this.authenticationService.autoLogout().subscribe(success => {

                    if (this.storageType == "session") {
                        this.storageHelperService.ClearSessionValue("currentuser");
                    }
                    else {
                        this.storageHelperService.ClearLocalValue("currentuser");
                    }
                    this.isLoggedInSubject.next(false);
                    this.currentUserSubject.next(null);
                    this.timer.stopAndKill();
                    this.timer.clearStartTime();

                }, error => {
                    //token is invalid - clear local storage and redirect to dashboard
                    console.log("token is invalid")

                });
            }
        }, 200);

    }

    getMessagePopupCloseFlag() {
        return this.isMessagePopupClosed;
    }

    setMessagePopupCloseFlag(closeFlag: boolean) {
        this.isMessagePopupClosed = closeFlag;
    }

    isLoggedIn(): Observable<boolean> {
        var currentUser = this.getCurrentUser();
        if (currentUser && currentUser.userId) {
            this.isLoggedInSubject.next(true);
        }
        else {
            this.isLoggedInSubject.next(false);
        }
        return this.isLoggedInSubject.asObservable();
    }

    getCurrentUserAsObservable(): Observable<any> {
        var currentUser: any;
        if (this.storageType == "session") {
            currentUser = this.storageHelperService.GetSessionValue("currentuser");
            this.currentUserSubject.next(currentUser);
        }
        else {
            currentUser = this.storageHelperService.GetLocalValue("currentuser");
            this.currentUserSubject.next(currentUser);
        }
        return this.currentUserObservable;

    }

    getEditPermission(route: any) {
        let routePermission = route.data.value["permission"] as string;
        if (routePermission == "" || routePermission == " " || routePermission == undefined || routePermission == null) {
            return false;
        }
        let user = this.getCurrentUser();

        for (let menu of user.permissions) {
            if (menu.hasChild && menu.children.length > 0) {
                for (let submenu of menu.children) {
                    if (submenu.menuName == routePermission) {
                        if (submenu.isEdit)
                            return true;
                        else
                            return false;
                    }
                }
            }
            else {
                if (menu.menuName == routePermission) {
                    if (menu.isEdit)
                        return true;
                    else
                        return false;
                }
            }
        }

        return false;
    }

    getEditPermissionUrl(url: any) {
        if (url == "" || url == " " || url == undefined || url == null) {
            return false;
        }
        let user = this.getCurrentUser();

        for (let menu of user.permissions) {
            if (menu.hasChild && menu.children.length > 0) {
                for (let submenu of menu.children) {
                    if (submenu.routes[0] == url) {
                        if (submenu.isEdit)
                            return true;
                        else
                            return false;
                    }
                }
            }
            else {
                if (menu.routes[0] == url) {
                    if (menu.isEdit)
                        return true;
                    else
                        return false;
                }
            }
        }

        return false;
    }

}
