import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicModalContainerComponent } from './dynamic-modal-loader.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OkCancelDialogComponent } from './ok-cancel-dialog-component/ok-cancel-dialog.component';
import { AlertDialogComponent } from './alert-dialog-component/alert-dialog.component';
import { InfoDialogComponent } from './info-dialog-component/info-dialog.component';
import { DeleteDialogDiscussionComponent } from './delete-dialog-discussion/delete-dialog-discussion.component';
// import { FormDialogComponent } from './form-dialog-component/form-dialog.component';
 import { ModalDialogService } from './modal-dialog.service';
 import { BrowserModule } from '@angular/platform-browser';
 import { PipeModule } from '../pipe/pipe.module';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
 import { ComponentModule } from './../component/component.module';
 import { MyDatePickerModule } from 'mydatepicker';
// import { ProgressBarModule } from '../progress-bar-module/progress-bar.module';
 import { EditDiscussionComponent } from './edit-discussion-component/edit-discussion.component';
 import { UserProfileDialogComponent } from "./user-profile-dialog-component/user-profile-dialog-component";
// import { DirectiveModule } from '../directive/directive.module';
import { PeoplePickerDialogComponent } from './people-picker-dialog/people-picker-dialog.component';
 import { TagDialogComponent } from "./tag-dialog-component/tag-dialog-component";
 import { AddEditDialogComponent } from './ht-addedit-dialog-component/ht-addedit-dialog.component';
import { ConfirmDeleteDialogComponent } from './confirm_delete/confirm-delete-dialog.component';
import { MediaUploadDialogComponent } from './media-upload-dialog.component/media-upload-dialog.component';
// import { SubDriverDialogComponent } from './sub-driver-dialog/sub-driver-dialog.component';
import { HtmlUploadDialogComponent } from './html-upload-dialog.component/html-upload-dialog.component';
import { CodeOfConductDialogComponent } from './code-of-conduct-component/code-of-conduct-dialogue.component';
import { DeactivateComponent } from './deactivate-component/deactivate.component';
import { ConfirmAnonymizeDialogComponent } from './confirm_anonymize/confirm-anonymize-dialog.component';

@NgModule({
    imports: [
        ModalModule.forRoot(),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        PipeModule,
        MalihuScrollbarModule.forRoot(),
         ComponentModule,
         MyDatePickerModule,
        // ProgressBarModule,
        // DirectiveModule
        
    ],
    declarations: [
         OkCancelDialogComponent,
         AlertDialogComponent,
        InfoDialogComponent,
        // FormDialogComponent,
         DeleteDialogDiscussionComponent,
         EditDiscussionComponent,
         UserProfileDialogComponent,
         TagDialogComponent,
        PeoplePickerDialogComponent,
         AddEditDialogComponent,
        ConfirmDeleteDialogComponent,
        MediaUploadDialogComponent,
        // SubDriverDialogComponent,
        HtmlUploadDialogComponent,
        CodeOfConductDialogComponent,
        DeactivateComponent,
        ConfirmAnonymizeDialogComponent
    ],
    providers: [ModalDialogService],
    bootstrap: [DynamicModalContainerComponent]
})
export class ModalDialogModule { }