<div class="modal fade ey-dialog-modal anonymize-modal" bsModal #anonymizeModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="floating-dialog-close pull-right" (click)="closeDialog()">
                    x&nbsp;close
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="dialog_title"> Please provide the reason to Anonymize user </div>                        
                        <div class="commentTitle">  {{deleteReason}} </div>
                        <textarea class="textAreaStyle" maxlength="100" rows="3" [(ngModel)]='deleteComments'>  </textarea>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="pull-right">
                        <button class="btn btn-ey-default buttonRight" [disabled]="deleteComments.length == 0" (click)="submitClicked();" type="button">Submit</button>
                        <button class="btn btnWhite buttonRight" (click)="cancelClicked();" type="button">Cancel</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>