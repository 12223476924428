<div class="modal fade ey-info-modal" bsModal #infoModal="bs-modal"
     tabindex="-1" role="dialog" (onHide)="onModalClosed($event)">
    <div class="modal-dialog modal-lg"
         [ngClass]="{'insight-preview-dialog': templateData.type == types.INSIGHT_PREVIEW_POPUP, 'image-dialog': templateData.mediaType == 'image'}"
         >
        <div class="modal-content">
            <div class="modal-body">
                <div class="floating-dialog-close pull-right" (click)="closeDialog()" *ngIf="!hideCloseButton">
                    x&nbsp;close
                </div>
                <div [ngSwitch]="templateType">
                    <ng-template [ngTemplateOutlet]="simpleMessageTemplate" *ngSwitchCase="types.SIMPLE">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="assesmentCompleted" *ngSwitchCase="types.ASSESSMENT_COMPLETED"></ng-template>

                    <ng-template [ngTemplateOutlet]="subDriverAssesmentCompleted"
                                 *ngSwitchCase="types.SUB_DRIVER_ASSESSMENT_COMPLETED"></ng-template>

                    <ng-template [ngTemplateOutlet]="themeAssesmentCompleted"
                                 *ngSwitchCase="types.THEME_ASSESSMENT_COMPLETED"></ng-template>

                    <ng-template [ngTemplateOutlet]="maturityLevelTemplate" *ngSwitchCase="types.MATURITY_LEVEL">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="insightPopupTemplate" *ngSwitchCase="types.INSIGHT_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="needHelpPopupTemplate" *ngSwitchCase="types.NEED_HELP_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="copyrightPopupTemplate" *ngSwitchCase="types.COPYRIGHT_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="GrowthPlanNoActionTemplate" *ngSwitchCase="types.GROWTH_PLAN_NO_ACTION">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="imageVideoPopupTemplate" *ngSwitchCase="types.IMAGE_VIDEO_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="insightPreviewPopupTemplate" *ngSwitchCase="types.INSIGHT_PREVIEW_POPUP">

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="defaultMessageTemplate" *ngSwitchDefault>

                    </ng-template>
                    <ng-template [ngTemplateOutlet]="AccountDeleteTemplate" *ngSwitchCase="types.ACCOUNT_DELETE">

                    </ng-template>
                    
                </div>


            </div>
        </div>
    </div>
</div>

<ng-template #defaultMessageTemplate>
    <div class="ey-info-modal-default">{{templateData.message}}</div>
</ng-template>

<ng-template #simpleMessageTemplate>
    <div class="ey-info-modal-default">{{templateData.message}}</div>
</ng-template>

<ng-template #assesmentCompleted>
    <div class="ey-info-modal-default">
        Congratulations! You have now finished scoring all your themes for
        your subdriver. The average of these scores is shown in the {{templateData.SubDriverName}} subdriver[<i class="fa fa-arrow-circle-o-right" style="color:green"></i>&nbsp;<i class="fa fa-arrow-circle-o-up" style="color:green"></i>]
        <br /><br />
        You now need to score your current and aspirational scores for this subdriver using the green markers [<i class="fa fa-arrow-circle-right" style="color:green"></i>&nbsp;<i class="fa fa-arrow-circle-up" style="color:green"></i>]
    </div>
</ng-template>

<ng-template #themeAssesmentCompleted>
    <div class="ey-info-modal-default">
        {{templateData.notifyText1}}
        [<i class="fa fa-arrow-circle-o-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-o-up fa-lg" style="color:green"></i>]
        <br /><br />
        {{templateData.notifyText2}}
        [<i class="fa fa-arrow-circle-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-up fa-lg" style="color:green"></i>]
    </div>
</ng-template>

<ng-template #subDriverAssesmentCompleted>
    <div class="ey-info-modal-default">
        {{templateData.notifyText1}}
        [<i class="fa fa-arrow-circle-o-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-o-up fa-lg" style="color:green"></i>]
        <br /><br />
        {{templateData.notifyText2}}
        [<i class="fa fa-arrow-circle-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-up fa-lg" style="color:green"></i>]
        <br /><br />

        {{templateData.notifyText3}}
        [<i class="fa fa-arrow-circle-right fa-lg" style="color:green">
        </i>&nbsp;<i class="fa fa-arrow-circle-up fa-lg" style="color:green"></i>]
    </div>
</ng-template>

<ng-template #maturityLevelTemplate>
    <div class="ey-info-modal-default">
        <h2 class="maturity-level-heading">{{templateData.name}}</h2>
        <div class="maturity-level-content">{{templateData.description}}</div>
    </div>
</ng-template>

<!--Template for Insight popup-->
<ng-template #insightPopupTemplate>
    <div class="insight-popup-content">
        <div class="pull-right link pin-title" (click)="onPinInsights()">
            <img *ngIf="templateData.isPinned" src="../../../assets/images/pinned-icon.png" />
            <img *ngIf="!templateData.isPinned" src="../../../assets/images/pin-icon.png" />
            <!--<img [src]="isPinned?'../../../../assets/images/pinned-icon.png':'../../../../assets/images/pin-icon.png'" alt="">  -->
            {{templateData.isPinned  ? SiteContents.labelPinnedThisInsight :SiteContents.labelPinThisInsight }}
        </div>
        <iframe width="100%" height="700" frameborder="0" [src]="templateData.content | safeUrl"></iframe>
    </div>
</ng-template>

<!--Template for Copyright popup-->
<ng-template #copyrightPopupTemplate>
    <div class="copyright-popup-content">
        <div [innerHTML]="templateData.content"></div>
    </div>
</ng-template>
<ng-template #needHelpPopupTemplate>
    <div class="need-help-content">
        <script src="../template-types.enum.js"></script>
        <!--[innerHTML]="templateData.content"-->
        <iframe width="100%" height="700" frameborder="0" [src]="templateData | safeUrl "></iframe>
    </div>
</ng-template>

<ng-template #GrowthPlanNoActionTemplate>
    <div class="ey-info-modal-default">
        <div class="no-Action-content">
            <p>{{templateData.description}}</p>
            <button class="btn btn-ey-default" (click)="onGrowthPlanAction()">{{templateData.buttonText}}</button>
        </div>
    </div>
</ng-template>

<ng-template #imageVideoPopupTemplate>
    <div class="tds-image-dialog">
        <img [src]="templateData.placeHolderUrl">
    </div>
</ng-template>

<ng-template #insightPreviewPopupTemplate>
    <div class="insight-preview-popup-content">
        <div class="insight-preview-title">Insight Preview</div>
        <iframe width="100%" frameborder="0" [src]="templateData.content | safeUrl"></iframe>
    </div>
</ng-template>


<ng-template #AccountDeleteTemplate>
    <div class="ey-info-modal-default">
        <div class="no-Action-content">
            <p>{{templateData.description}}</p>
            <button class="btn btn-ey-default" (click)="goToListPage()">OK</button>
        </div>
    </div>
</ng-template>