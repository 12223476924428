import { NgModule } from '@angular/core';
import { SafeUrl } from './safeUrl.pipe';
import { Replace } from "./replaceText.pipe";
import { SortPipe } from "./sortBy.pipe";
import { SelectFilterPipe } from "./select-filter.pipe";
import { dateFormatter } from './date-formatter.pipe'
import { CountFormatter } from './count-formatter.pipe';
import { DatexPipe } from './custom-date.pipe'

@NgModule({
    imports: [],
    declarations: [
        SafeUrl, 
        Replace, 
        SortPipe,
        SelectFilterPipe,
        dateFormatter,
        CountFormatter,
        DatexPipe
    ],
    exports: [
        SafeUrl, 
        Replace, 
        SortPipe,
        SelectFilterPipe,
        dateFormatter,
        CountFormatter,
        DatexPipe
    ]
})
export class PipeModule { }