import { Component, OnInit, Injector, ViewChild, ElementRef } from '@angular/core';
import { UtilService } from '../../service/util.service';
import { Router, NavigationEnd, Event, NavigationStart } from '@angular/router';
import { UserService } from './../../auth/service/user.service';
import { Constants } from './../../service/constant.service';
import { AuthenticationService } from "../../auth/service/auth.service";
import { ModalDialogService } from "../../modal-dialog-module/modal-dialog.service";
import { LogService } from '../../service/log.service';
import { LogTypes, LogAction } from '../../service/model/log-models';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
})

export class AppHeaderComponent implements OnInit {
    
    brandName: string;
    isLoggedIn: boolean;
    userName: string;

    constructor(
        private utilService: UtilService,
        private constants: Constants,
        private userService: UserService,
        private router: Router,
        private injector: Injector,
        private elementRef: ElementRef,
        private authService: AuthenticationService,
        private dialogService: ModalDialogService,
        private logTypes: LogTypes,
        private logService: LogService
    ) {

    }

    ngOnInit() {
        
        this.brandName = this.constants.APP.TITLE;

        this.userService.isLoggedIn().subscribe((x: boolean) => {
            this.isLoggedIn = x;
            
        });

        this.userName = this.userService.getCurrentUser().preferredName;
    }

    logout() {
        let config: any = {
            type: 'LOGOUT',
            action: {
                cancel: "No",
                ok: "Yes"
            }
        };
        const dialog = this.dialogService.confirm(
            "Logout",
            "Are you sure you want to logout ?",
            config);

        dialog.subscribe(
            (data) => {
                if (data && data === 'yes') {
                    // log the user logout
                    let log = new LogAction();
                    log.action = this.logTypes.LOGGED_OUT;
                    this.logService.logAction(log);
                    this.userService.logoutmanual();
                    this.authService.logout();
                }
            }
        );
        
    }
    
    
}
