// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environmentName: 'dev',

    //Old Classic Evnvironment

    //VELOCITY_API: 'https://velocityapi.vel-dev.ey.com/api/',
    //GROWTH_NAVIGATOR_API: 'https://velocitygnapi.vel-dev.ey.com/api/',
    //QUERY_ENGINE_API: 'https://velocityqeapi.vel-dev.ey.com/api/',
    //AZURE_TENANT: 'https://login.microsoftonline.com/eygs.onmicrosoft.com/',
    //AZURE_CLIENTID: '8cd8f909-3480-4d00-82eb-8df749cc7b8c',
    //DOMAIN_HINT: 'ey.com',
    //USE_LoGIN_DEV: true

     //New Fabric Environement
    VELOCITY_API: 'https://velocityapi.vel-dev1.ey.com/api/',
    GROWTH_NAVIGATOR_API: 'https://velocitygnapi.vel-dev1.ey.com/api/',
    QUERY_ENGINE_API: 'https://velocityqeapi.vel-dev1.ey.com/api/',
    AZURE_TENANT: 'https://login.microsoftonline.com/eyqa.onmicrosoft.com/',
    AZURE_CLIENTID: 'ae0d7c16-1de9-493c-a20f-052095b8375e',
    DOMAIN_HINT: 'ey.com',
    USE_LoGIN_DEV: true

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
