import { Injectable, ReflectiveInjector, ComponentFactoryResolver } from '@angular/core';
import { OkCancelDialogComponent } from './ok-cancel-dialog-component/ok-cancel-dialog.component';
import { AlertDialogComponent } from './alert-dialog-component/alert-dialog.component';
import { InfoDialogComponent } from './info-dialog-component/info-dialog.component';
// import { FormDialogComponent } from './form-dialog-component/form-dialog.component';
 import { ComponentData } from './component-data';
 import { TemplateTypes, DialogTypes } from './template-types.enum'
 import { Router, NavigationStart } from '@angular/router';
 import { DeleteDialogDiscussionComponent } from './delete-dialog-discussion/delete-dialog-discussion.component';
 import { EditDiscussionComponent } from './edit-discussion-component/edit-discussion.component';
 import { UserProfileDialogComponent } from "./user-profile-dialog-component/user-profile-dialog-component";
 import { TagDialogComponent } from "./tag-dialog-component/tag-dialog-component";
import { PeoplePickerDialogComponent } from './people-picker-dialog/people-picker-dialog.component';
 import { AddEditDialogComponent } from './ht-addedit-dialog-component/ht-addedit-dialog.component';
import { DeactivateComponent } from './deactivate-component/deactivate.component';
import { ConfirmDeleteDialogComponent } from './confirm_delete/confirm-delete-dialog.component';
import { MediaUploadDialogComponent } from './media-upload-dialog.component/media-upload-dialog.component';
// import { SubDriverDialogComponent } from './sub-driver-dialog/sub-driver-dialog.component';
import { HtmlUploadDialogComponent } from './html-upload-dialog.component/html-upload-dialog.component';
import { CodeOfConductDialogComponent } from './code-of-conduct-component/code-of-conduct-dialogue.component';
import { filter } from 'rxjs/operators';
import { ConfirmAnonymizeDialogComponent } from './confirm_anonymize/confirm-anonymize-dialog.component';


@Injectable()
export class ModalDialogService {
    containerElementRef: any;
    componentData: ComponentData = new ComponentData();
    constructor(private resolver: ComponentFactoryResolver) { }

     alert(title: string = "", message: string = "", showCloseButton?: boolean) {
         this.componentData = Object.assign(new ComponentData(), {
             component: AlertDialogComponent,
             componentInput: {
                 title: title,
                 message: message,
                 showCloseButton: showCloseButton
             }
         });
         return this.containerElementRef.showDialog(this.componentData);
     }


     confirm(title: string = "", message: string = "", config?: any) {
         this.componentData = Object.assign(new ComponentData(), {
             component: OkCancelDialogComponent,
             componentInput: {
                 title: title,
                 message: message,
                 config: config
             }
         });
         return this.containerElementRef.showDialog(this.componentData);
     }
    
    confirmDelete(title: string = "", message: string = "", config?: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ConfirmDeleteDialogComponent,
            componentInput: {
                title: title,
                message: message,
                config: config
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    // close() {
    //     this.containerElementRef.closeDialog();
    // }

    info(templateType: any, templateData: any, hideCloseButton?: boolean) {
        this.componentData = Object.assign(new ComponentData(), {
            component: InfoDialogComponent,
            componentInput: {
                templateType: templateType,
                templateData: templateData,
                hideCloseButton: hideCloseButton
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    addEditDialog(dialogType: any, dialogData?: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: AddEditDialogComponent,
            componentInput: {
                dialogType: dialogType,
                dialogData: dialogData
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

     addDeactivateDialog(dialogData?: any) {
         this.componentData = Object.assign(new ComponentData(), {
             component: DeactivateComponent,
             componentInput: {
                 dialogData: dialogData
             }
         });
         return this.containerElementRef.showDialog(this.componentData);
     }

    // formModal(title: string = "", message: string = "", config?: any) {
    //     this.componentData = Object.assign(new ComponentData(), {
    //         component: FormDialogComponent,
    //         componentInput: {
    //             title: title,
    //             message: message,
    //             config: config
    //         }
    //     });
    //     return this.containerElementRef.showDialog(this.componentData);
    // }

    // subDriverModal(title: string = "", message: string = "", config?: any) {
    //     this.componentData = Object.assign(new ComponentData(), {
    //         component: SubDriverDialogComponent,
    //         componentInput: {
    //             title: title,
    //             message: message,
    //             config: config
    //         }
    //     });
    //     return this.containerElementRef.showDialog(this.componentData);
    // }

    unsubscribe() {
        this.containerElementRef.unsubscribe();
    }

    closeDialogOnNavigationStart(router: Router) {

        router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe((x: any) => {
            this.unsubscribe();
        });
    }

    editDisussion(content: any, isAnswer: boolean) {
        console.log('edit');
        this.componentData = Object.assign(new ComponentData(), {
            component: EditDiscussionComponent,
            componentInput: {
                content: content,
                isAnswer: isAnswer
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    showAnonymizePopup(content:any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: ConfirmAnonymizeDialogComponent,
            componentInput: {
                anonymizeReason: content
            }
        });
        let _var = this.containerElementRef.showDialog(this.componentData);
        return _var;
    }

    showDeletePopup(content: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: DeleteDialogDiscussionComponent,
            componentInput: {
                discussionDetails: content
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    userProfile(userID: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: UserProfileDialogComponent,
            componentInput: {
                userID: userID
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    tagPopup(tags: any) {
        this.componentData = Object.assign(new ComponentData(), {
            component: TagDialogComponent,
            componentInput: {
                tags: tags
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    peoplePicker(source: string, selectedUser: any[]) {
        this.componentData = Object.assign(new ComponentData(), {
            component: PeoplePickerDialogComponent,
            componentInput: {
                source: source,
                selectedUser: selectedUser
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    mediaUpload(mediaType: number, mediaUrl: string, thumbnailUrl: string, mediaFullUrl: string, thumbnailFullUrl: string, mediaContainer: string = 'velmedia') {
        this.componentData = Object.assign(new ComponentData(), {
            component: MediaUploadDialogComponent,
            componentInput: {
                mediaType: mediaType,
                mediaUrl: mediaUrl,
                thumbnailUrl: thumbnailUrl,
                mediaFullUrl: mediaFullUrl,
                mediaThumbnailFullUrl: thumbnailFullUrl,
                mediaContainer: mediaContainer
            }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

    htmlUpload(title) {
        this.componentData = Object.assign(new ComponentData(), {
            component: HtmlUploadDialogComponent,
            componentInput: {
                title: title,
                //mediaUrl: mediaUrl,
                //thumbnailUrl: thumbnailUrl,
                //mediaFullUrl: mediaFullUrl,
                //mediaThumbnailFullUrl: thumbnailFullUrl,
                //mediaContainer: mediaContainer
            }
          });
        return this.containerElementRef.showDialog(this.componentData);
    }

    codeOfConductModel(htmlURL: string, policyType: number, headingString: string, uploadedDate:string) {
        this.componentData = Object.assign(new ComponentData(), {
            component: CodeOfConductDialogComponent,
            componentInput: { htmlURL: htmlURL, policyType: policyType, headingString: headingString, uploadedDate: uploadedDate }
        });
        return this.containerElementRef.showDialog(this.componentData);
    }

}
